import {ContentLocale} from '@paperlessio/sdk/api/util';
import {Block, BlockSettings, BlockStyles} from './block';
import {RexCalculationType} from '../calculation-type';
import {excluded} from '../util/excluded';

type LocalizedAttributes = {
  settings: {
    name: string,
    placeholder: string;
    help_text: string;
  }
}

type LocalizedValidationRuleAttributes = {
  message: string
}

export class ValidationRule {
  uuid: string;

  // The ID in directus
  @excluded id: number;
  rule_calculation_type: RexCalculationType.JAVASCRIPT | RexCalculationType.JSON_LOGIC;
  rule_calculation_javascript_definition: string;
  rule_calculation_json_logic_definition: object;
  message: string;
  localized_attributes: Partial<Record<ContentLocale, LocalizedValidationRuleAttributes>>;

  constructor(v: Partial<ValidationRule> = {}) {
    this.uuid = v.uuid ?? '';
    this.id = v.id;
    this.rule_calculation_type = v.rule_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.rule_calculation_javascript_definition = v.rule_calculation_javascript_definition;
    this.rule_calculation_json_logic_definition = v.rule_calculation_json_logic_definition;
    this.message = v.message ?? '';
    this.localized_attributes = v.localized_attributes ?? {} as Record<ContentLocale, LocalizedValidationRuleAttributes>;
  }

  public localizedMessage(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    if (locale === bucketOriginalLocale) {
      return this.message;
    } else {
      return this.localized_attributes[locale]?.message || (fallback ? this.message : null);
    }
  }

  public setLocalizedMessage(locale: ContentLocale, bucketOriginalLocale: ContentLocale, message: string) {
    if (locale === bucketOriginalLocale) {
      this.message = message;
    } else {
      this.ensureLocalizedPresetForLocale(locale);
      this.localized_attributes[locale].message = message;
    }
  }

  public get rexIdentifier(): string {
    return `validation_rules.${this.uuid}.rule`;
  }

  private ensureLocalizedPresetForLocale(locale: ContentLocale) {
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as LocalizedValidationRuleAttributes;
    }
  }
}

export class Input extends Block {
  slug: string;
  default_value: any;
  settings: InputSettings;
  styles: InputStyles;
  localized_attributes: Record<ContentLocale, LocalizedAttributes>;
  validation_rules: ValidationRule[];

  required_calculation_type: RexCalculationType;
  required_calculation_javascript_definition: string;
  required_calculation_json_logic_definition: object;

  @excluded private defaultValueBySlug = {};

  constructor(v: Partial<Input> = {}) {
    super(v);
    this.slug = v.slug;
    this.settings = new InputSettings(v.settings ?? {});
    this.styles = new InputStyles(v.styles ?? {});
    this.default_value = v.default_value;
    this.validation_rules = (v.validation_rules ?? []).map(rule => new ValidationRule(rule));
  }

  // Datasets are using one block multiple times, so we create and cache default_values
  // per slug, to make sure mutable data structures are not causing leakages between instances
  // of the same block.
  public defaultValueForSlug(slug: string): string {
    if (slug === this.slug) {
      return this.default_value;
    }
    if (this.default_value && !this.defaultValueBySlug[slug]) {
      this.defaultValueBySlug[slug] = JSON.parse(JSON.stringify(this.default_value));
    }
    return this.defaultValueBySlug[slug];
  }

  get calculated(): boolean {
    return super.calculated || this.required_calculation_type !== RexCalculationType.CONSTANT || this.validation_rules.length > 0;
  }

  get validated(): boolean {
    // Note: In reality this.settings.validate is only used for text-inputs, however there is now actual guarantee, so until we got rid of
    // it in favour of validation_rules, we need to include it here.
    return this.settings.required || this.settings.validate || this.validation_rules.length > 0;
  }

  localizedPlaceholder(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedSettingsAttribute('placeholder', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedPlaceholder(locale: ContentLocale, bucketOriginalLocale: ContentLocale, placeholder: string) {
    this.writeLocalizedSettingsAttribute('placeholder', locale, bucketOriginalLocale, placeholder);
  }

  localizedHelpText(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedSettingsAttribute('help_text', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedHelpText(locale: ContentLocale, bucketOriginalLocale: ContentLocale, help_text: string) {
    this.writeLocalizedSettingsAttribute('help_text', locale, bucketOriginalLocale, help_text);
  }

  validationRuleByUUID(uuid: string): ValidationRule {
    const rule = this.validation_rules.find(rule => rule.uuid === uuid);
    if (!rule) {
      throw new Error(`ValidationRule with uuid ${uuid} not found`);
    }
    return rule;
  }
}

export class InputSettings extends BlockSettings {
  name: string;
  placeholder: string;
  validate: boolean;
  help_text: string;
  hide_name: boolean;

  private _required: boolean;
  private _readonly: boolean;

  get required(): boolean {
    return this._required;
  }

  set required(v: boolean) {
    this._required = v;
    if (v) {
      this._readonly = false;
    }
  }

  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(v: boolean) {
    this._readonly = v;
    if (v) {
      this._required = false;
    }
  }

  constructor(v: Partial<InputSettings> = {}) {
    super(v);
    this.name = v.name ?? '';
    this.placeholder = v.placeholder ?? '';
    this._required = v.required ?? false;
    this._readonly = v.readonly ?? false;
    this.validate = v.validate ?? false;
    this.help_text = v.help_text ?? '';
    this.hide_name = v.hide_name ?? false;
  }

  // We don't want to serialize the private fields with their prefixed _ in the name so the assignment in the constructor works.
  toJSON() {
    let obj = JSON.stringify({...this});
    Object.keys(this).forEach(key => {
      if (key.startsWith('_')) {
        obj = obj.replace(key, key.substring(1));
      }
    });

    return JSON.parse(obj);
  }
}

export class InputStyles extends BlockStyles {
  constructor(v: Partial<InputStyles> = {}) {
    super(v);
  }
}
