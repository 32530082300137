import {DeltaStatic} from 'quill';
import {Point} from '@angular/cdk/drag-drop';
import {EditableSettings} from './editable-settings';
import {BlockType} from './block-type';
import {ContentLocale} from '@paperlessio/sdk/api/util';
import {TokenHelper} from '@paperlessio/sdk/util/helpers';
import {RexCalculationType} from '../calculation-type';
import {excluded} from '../util/excluded';
import {Size} from '../util/size';

type LocalizedAttributes = {
  settings: {
    name: string
  }
}

export class Block {
  id: number;
  slug: string;
  position: number;
  topologicalPosition: number;
  type: BlockType;
  visible: boolean;
  visible_calculation_type: RexCalculationType;
  visible_calculation_javascript_definition: string;
  visible_calculation_json_logic_definition: object;
  settings: BlockSettings;
  styles: BlockStyles;
  parent_id: number;
  organization_id: number;
  created_at: Date;
  updated_at: Date;
  bucket_type: string | BlockType;
  bucket_id: number;
  local_uuid?: string | null;
  parent_local_uuid?: string | null;
  parent?: Block;
  children: Block[] = [];
  // MUST NOT be used in submission context. MUST ONLY be used in bucket context
  block_owner_participant_ids: number[];
  default_value?: any;
  localized_attributes: Record<ContentLocale, LocalizedAttributes>;

  @excluded disableDropzone: { top?: boolean, right?: boolean, bottom?: boolean, left?: boolean };
  @excluded disableDropping: { top?: boolean, right?: boolean, bottom?: boolean, left?: boolean };
  @excluded dropOnPageOnly: boolean;

  constructor(v: Partial<Block> = {}) {
    this.visible = true;
    Object.assign(this, v);
    this.settings = new BlockSettings(v.settings ?? {});
    this.styles = new BlockStyles(v.styles ?? {});
    this.block_owner_participant_ids = v.block_owner_participant_ids || [];
    this.created_at = v.created_at ? new Date(v.created_at) : new Date();
    this.updated_at = v.updated_at ? new Date(v.updated_at) : new Date();
    this.localized_attributes = v.localized_attributes ?? {} as Record<ContentLocale, LocalizedAttributes>;

    this.disableDropzone = {
      top: false,
      right: false,
      bottom: false,
      left: false,
      ...v.disableDropzone
    };

    this.disableDropping = {
      top: false,
      right: false,
      bottom: false,
      left: false,
      ...v.disableDropping
    };

    this.dropOnPageOnly = v.dropOnPageOnly ?? false;
  }

  get _hasToken(): boolean {
    return TokenHelper.hasToken(this.settings.name, this.settings.content);
  }

  get tokens(): string[] {
    const res = TokenHelper.extractTokens(this.settings.name, this.settings.content) || [];
    return [].concat.apply([], res);
  }

  get visibleCalculation(): boolean {
    return this.visible_calculation_type !== RexCalculationType.CONSTANT;
  }

  set visibleCalculation(value: boolean) {
    if (value === true && this.visible_calculation_type === RexCalculationType.CONSTANT) {
      this.visible_calculation_type = RexCalculationType.JAVASCRIPT;
      if (!this.visible_calculation_javascript_definition) {
        this.visible_calculation_javascript_definition = 'true';
      }
      if (!this.visible_calculation_json_logic_definition) {
        this.visible_calculation_json_logic_definition = {};
      }
    } else if (value === false) {
      this.visible_calculation_type = RexCalculationType.CONSTANT;
    }
  }

  get calculated(): boolean {
    return this.visible_calculation_type !== RexCalculationType.CONSTANT;
  }

  get tokenCount(): number {
    let count = this.settings.content ? (this.settings.content.match(TokenHelper.REGEX) || []).length : 0;
    count += this.settings.name ? (this.settings.name.match(TokenHelper.REGEX) || []).length : 0;
    return count;
  }

  get isInput(): boolean {
    return this.type.includes('::Input::');
  }

  get hasPdfPartParent(): boolean {
    return this.parent != null && this.parent.type === BlockType.PdfPart ? true : this.parent?.hasPdfPartParent ?? false;
  }

  @excluded private classMemoized: string;

  get class(): string {
    if (!this.classMemoized) {
      this.classMemoized = this.type ? this.type.split('::').join('-').toLowerCase() : '';
    }
    return this.classMemoized;
  }

  addChild(block: Block, position: number) {
    // remove from old parent
    block.remove();

    // update properties
    block.parent_id = this.id;
    block.parent_local_uuid ||= this.local_uuid;
    block.parent = this;
    block.position = position;

    // find index of next sibling
    const children = this.children;
    const index = children.findIndex(child => child.position >= position);

    // rebuild children

    // TODO: It would be better if the backend always updates positions properly.
    //       The following solution shifts the positions instead of sanitizing them.
    //       This seems to work but might have side effects.

    // Solution with shifting positions

    if (index === -1) {
      this.children = [...children, block];

      return;
    }

    const childrenAfterBlock = children.slice(index);

    for (const child of childrenAfterBlock) {
      child.position += 1;
    }

    this.children = [...children.slice(0, index), block, ...childrenAfterBlock];

    // Solution with sanitizing positions

    // this.children = index === -1
    //   ? [...children, block]
    //   : [...children.slice(0, index), block, ...children.slice(index)];

    // sanitize positions
    // for (const [index, child] of this.children.entries()) {
    //   child.position = index;
    // }
  }

  // TODO: JSDOC!
  remove() {
    delete this.parent_id;

    if (!this.parent) {
      return;
    }

    this.parent.children = this.parent.children.filter(child => child.id !== this.id);
  }

  toJSON() {
    // remove parent and children attributes before serialization
    const {parent, children, ...clean} = this;
    return clean;
  }

  // This must only be used in submittable context -- i.e. editor is fine, while submission, render etc. is not!
  ownedByParticipant(id: number | undefined) {
    if (!id) {
      return false;
    } else if (this.block_owner_participant_ids?.length === 0) {
      return false;
    } else if (this.block_owner_participant_ids?.indexOf(id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  localizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedSettingsAttribute('name', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, name: string) {
    this.writeLocalizedSettingsAttribute('name', locale, bucketOriginalLocale, name);
  }

  protected readLocalizedSettingsAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    fallback: boolean): any {
    if (locale === bucketOriginalLocale) {
      return this.settings[attribute];
    } else {
      return this.localized_attributes[locale]?.settings?.[attribute] || (fallback ? this.settings[attribute] : null);
    }
  }

  protected writeLocalizedSettingsAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    value: any) {
    if (locale === bucketOriginalLocale) {
      this.settings[attribute] = value;
    } else {
      this.ensureLocalizedSettingsPresetForLocale(locale);
      this.localized_attributes[locale].settings[attribute] = value;
    }
  }

  protected ensureLocalizedSettingsPresetForLocale(locale: ContentLocale) {
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as LocalizedAttributes;
    }
    if (!this.localized_attributes[locale].settings) {
      this.localized_attributes[locale].settings = {} as any;
    }
  }
}

export class BlockStyles {
  constructor(v: Partial<BlockStyles> = {}) {
    Object.assign(this, v);
  }

  [key: string]: any;
}

export class BlockSettings {
  name: string;

  // TODO: move to rich text
  content: string;
  delta: DeltaStatic;
  hasToken: boolean;
  editable: EditableSettings;
  hideInExport: boolean;

  absolutePosition: Point;
  absoluteSize: Size;
  @excluded resizeAxis: 'x' | 'y' | 'both';
  @excluded aspectRatio: number;

  get minSize(): Size {
    return new Size();
  }

  /**
   * @deprecated Don't use this. JS needs it because of our Object.assign() calls but we don't use it.
   */
  set minSize(x) {
  }

  get maxSize(): Size {
    return new Size();
  }

  /**
   * @deprecated Don't use this. JS needs it because of our Object.assign() calls but we don't use it.
   */
  set maxSize(x) {
  }

  get defaultSize(): Size {
    return new Size();
  }

  /**
   * @deprecated Don't use this. JS needs it because of our Object.assign() calls but we don't use it.
   */
  set defaultSize(x) {
  }

  get dynamicBorderSize(): number {
    let val = this.absoluteSize.width / 30;

    val = val < 1 ? 1 : val;
    val = val > 10 ? 10 : val;

    return val;
  }

  constructor(v: Partial<BlockSettings> = {}) {
    Object.assign(this, v);
    this.name = v.name ?? '';
    this.content = v.content ?? '';
    this.delta = v.delta ?? undefined;
    this.hasToken = v.hasToken ?? false;
    this.editable = new EditableSettings(v.editable ?? {});
    this.hideInExport = v.hideInExport ?? false;

    this.absolutePosition = v.absolutePosition ?? {x: 0, y: 0};
    this.absoluteSize = new Size(!v.absoluteSize || (v.absoluteSize.width === 0 && v.absoluteSize.height === 0) ?
      this.defaultSize : v.absoluteSize);
    this.resizeAxis = v.resizeAxis ?? 'both';
  }
}

export class BlockWidth {
  unit: 'full' | 'percent' | 'pixel';
  value: number;

  constructor(v: Partial<BlockWidth> = {}) {
    this.unit = v.unit ?? 'full';
    this.value = v.value ?? 50;
  }

  get formatted() {
    switch (this.unit) {
      case 'percent':
        return `${this.value}%`;
      case 'pixel':
        return `${this.value}px`;
      default:
        return '100%';
    }
  }
}
